import './UserManagement.scss'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/AddCircle'
import Switch from '@mui/material/Switch'

import { forwardRef, useEffect, useState } from 'react'
import { Divider,
    List,
    ListItem,
    ListItemText,
    TextField,
    DialogContent,
    Container,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Tabs,
    Tab,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem,
    FormHelperText } from '@mui/material'
import { useAddOptionMutation,
    useDeleteOptionMutation,
    useEditOptionMutation,
    useUpdateOptionsMutation } from '../../../api/options/api.options'
import { useDeleteUserMutation,
    useEditUserMutation,
    useGetUsersQuery } from '../../../api/users/api.users'
import { DataGrid,
    GridApi,
    GridCellValue,
    GridColDef,
    GridValueGetterParams } from '@mui/x-data-grid'
import { useGetMeQuery } from '../../../api/auth/api.auth'

function EditUserDialog ( props: any ) {
    const { open, setOpen, user } = props
    const [ level, setLevel ] = useState( user.level )
    const [ enabled, setEnabled ] = useState( user.enabled )

    const { data: me } = useGetMeQuery( undefined, { skip: false })

    const [ editUser, userEditedResult ] = useEditUserMutation()
    const [ deleteUser, deleteUserResult ] = useDeleteUserMutation()

    useEffect(() => {
        if ( open ) {
            setLevel( user.level )
            setEnabled( user.enabled )
        }
    }, [ open ])

    function editUserAndClose () {
        if ( user.id != me?._id ) {
            if ( level === 0 && enabled ) {
                alert(
                    "You have set the organization's status to 'Enabled', but the organization's permissions are still set to 'Unactivated User'. Disable the user or escalate the user's privilege level to enable them."
                )
            } else {
                editUser({ id: user.id, level, enabled })
                handleClose()
            }
        }
    }

    function handleClose () {
        setOpen( false )
    }
    const handleLevelChange = ( event: SelectChangeEvent ) => {
        setLevel( event.target.value )
    }

    const handleEnabledChange = ( event: SelectChangeEvent ) => {
        setEnabled( !enabled )
    }

    function confirmDeleteUser () {
        if (
            confirm(
                'Are you sure you want to delete ' +
                    user.organization +
                    "'s account?"
            )
        ) {
            deleteUser({ id: user.id })
            handleClose()
        }
    }

    return (
        <Dialog
            className="edit-user-dialog"
            open={open}
            onClose={() => setOpen( false )}>
            <DialogTitle>Edit User Privileges</DialogTitle>
            <DialogContent>
                <h3 className="center">Organization: {user.organization}</h3>
                <p className="center">Email: {user.email}</p>
                <div className="edit-content">
                    <List>
                        <ListItem>
                            <FormControl>
                                <FormHelperText>
                                    Account Permission Level
                                </FormHelperText>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={level}
                                    label="Privilege Level"
                                    onChange={handleLevelChange}>
                                    <MenuItem value={0}>
                                        Unactivated User
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Referring Dealer
                                    </MenuItem>
                                    <MenuItem value={2}>Customer</MenuItem>
                                    <MenuItem value={3}>
                                        Credit Cars Sales Employee
                                    </MenuItem>
                                    <MenuItem value={4}>Administrator</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                        <Divider />
                        {me?._id !== user.id && (
                            <>
                                <ListItem>
                                    <div className="switch">
                                        <p>Account Enabled</p>
                                        <Switch
                                            checked={enabled}
                                            onChange={handleEnabledChange}
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}/>
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <Button
                                        className="delete-user"
                                        variant="contained"
                                        onClick={() => confirmDeleteUser()}>
                                        Delete User
                                    </Button>
                                </ListItem>
                            </>
                        )}
                    </List>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={() => editUserAndClose()}>Update User</Button>
            </DialogActions>
        </Dialog>
    )
}

// function DeleteOptionDialog ( props: any ) {
//     const { open, setOpen, category, option } = props

//     const [ deleteOption, optionDeletedResult ] = useDeleteOptionMutation()
//     const [ updateOptions, optionsUpdatedResult ] = useUpdateOptionsMutation()

//     const { data: buyerCategories } = useGetBuyerCategoriesQuery()
//     const { data: cosignerCategories } = useGetCosignerCategoriesQuery()

//     function deleteAndClose () {
//         if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
//             deleteOption({ id: category._id, name: option.display_name })
//             if ( category.type === "buyer" ) {
//                 const thisOptionGroup = buyerCategories.find(( cat: Category ) => cat._id === category._id )?.options
//                 const adjusted = thisOptionGroup?.filter(( opt ) => opt.display_name !== option.display_name )
//                 const newReorderedOptions = adjusted?.map(( cat: any, i ) => {
//                     return { ...cat, order: i + 1 }
//                 })
//                 updateOptions({ options: newReorderedOptions ?? [], id: category._id })
//             } else {
//                 const adjusted = cosignerCategories.filter(( cat ) => cat._id !== category._id )
//                 const newReorderedCategories = adjusted.map(( cat: any, i ) => {
//                     return { ...cat, order: i + 1 }
//                 })
//                 // updateOptions( newReorderedCategories )
//             }
//             setOpen( false )
//         }
//     }
//     return (
//         <Dialog className="edit-dialog" open={open} onClose={() => setOpen( false )}>
//             <DialogTitle>Delete Option</DialogTitle>
//             <DialogContent>
//                 <h4>Are you sure you want to delete {option.display_name}?</h4>
//                 <h4>This will permanently delete this option!</h4>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={() => setOpen( false )}>Cancel</Button>
//                 <Button onClick={deleteAndClose}>Delete Option</Button>
//             </DialogActions>
//         </Dialog>
//     )
// }

function UserManagement () {
    const { data: usersData } = useGetUsersQuery()
    const [ users, setUsers ] = useState([] as any )

    useEffect(() => {
        if ( usersData ) {
            if ( usersData.length > 0 ) {
                setUsers( usersData )
            }
        }
    }, [ usersData ])

    const [ editDialogOpen, setEditDialogOpen ] = useState( false )
    const [ selectedUser, setSelectedUser ] = useState({} as any )

    const columns: GridColDef[] = [
        { field: 'organization', headerName: 'Organization', width: 200 },
        { field: 'email', headerName: 'Email', width: 300 },
        { field: 'phone', headerName: 'Phone Number', width: 175 },
        {
            field: 'enabled',
            headerName: 'Account Enabled',
            type: 'boolean',
            width: 150,
        },
        {
            field: 'level',
            headerName: 'Level',
            type: 'number',
            width: 200,
            renderCell: ( params ) => {
                switch ( params.value ) {
                case 0:
                    return 'Unactivated User'
                case 1:
                    return 'Referring Dealer'
                case 2:
                    return 'Customer'
                case 3:
                    return 'Credit Cars Sales Employee'
                case 4:
                    return 'Administrator'
                default:
                    return ''
                }
            },
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            renderCell: ( params ) => {
                const onClick = ( e: any ) => {
                    e.stopPropagation() // don't select this row after clicking

                    setSelectedUser( params.row )
                    setEditDialogOpen( true )
                }
                return (
                    <IconButton onClick={onClick}>
                        <EditIcon />
                    </IconButton>
                )
            },
        },
    ]

    return (
        <div className="user-management">
            <h4>User Management</h4>
            <EditUserDialog
                open={editDialogOpen}
                setOpen={setEditDialogOpen}
                user={selectedUser}/>
            <DataGrid
                rows={users}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[ 5 ]}
                className="user-data-table"/>
        </div>
    )
}

export default UserManagement
