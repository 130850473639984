import { combineReducers, configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { api } from "./api/api"
import { logoutSlice } from "./api/logout"

const combinedReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    logout: logoutSlice.reducer
})
  
const rootReducer = ( state: any, action: any ) => {
    if ( action.type === 'logout/logout' ) {
        state = undefined
    }
    return combinedReducer( state, action )
}
  
export const createStore = (
    options?: ConfigureStoreOptions["preloadedState"] | undefined
) => configureStore({
    reducer: rootReducer, 
    middleware: ( getDefaultMiddleware ) => getDefaultMiddleware().concat( api.middleware ),
    ...options
})

export const store = createStore()

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store