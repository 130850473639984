import './Navigation.scss'
import { ReactComponent as CCSLogo } from '../../assets/ccs.svg'
import { Button,
    Drawer,
    Toolbar,
    List,
    ListItem,
    ListItemIcon } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ViewIcon from '@mui/icons-material/VisibilityRounded'
import CalculateIcon from '@mui/icons-material/CalculateRounded'
import IconButton from '@mui/material/IconButton'
import AdminIcon from '@mui/icons-material/ManageAccountsRounded'
import InstructionsIcon from '@mui/icons-material/Description'

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetMeQuery,
    useLogoutMutation,
    useRenewQuery } from '../../api/auth/api.auth'

function Navigation () {
    const [ isDrawerOpen, setIsDrawerOpen ] = useState( false )
    const navigate = useNavigate()
    const location = useLocation()

    // const [ validSession, setValidSession ] = useState( false )
    const unprotectedRoutes = [ '/', '/reset', '/register', '/pending' ]
    const path = '/' + location.pathname.split( '/' )[1]
    const { data: me, isError: meFailed } = useGetMeQuery( undefined, {
        skip: unprotectedRoutes.includes( path ),
    })
    // const { data: session, isError: sessionFailed } = useRenewQuery( undefined, {
    //     skip: me == undefined,
    //     pollingInterval: 360000,
    // })

    const [ logoutRequest, logoutRequestResult ] = useLogoutMutation()

    useEffect(() => {
        //console.log( location )
    }, [ location ])

    function navigateAndCloseDrawer ( path: string ) {
        navigate( path )
        setIsDrawerOpen( false )
    }

    function logout () {
        logoutRequest()
        localStorage.setItem( 'logged in', 'true' )
        navigate( '/' )
    }

    useEffect(() => {
        if ( me ) {
            //console.log( me.level )
            if ( me._id !== '' ) {
                // setValidSession( true )
                if ( location.pathname === '/' ) {
                    navigate( '/score' )
                }
            }
        } else {
            // setValidSession( false )
        }
    }, [ me ])

    // useEffect(() => {
    //     if ( session ) {
    //         if ( session._id !== "" ) {
    //             setValidSession( true )
    //         }
    //     } else {
    //         setValidSession( false )
    //         navigate( "/" )
    //     }
    // }, [ session ])

    // useEffect(() => {
    //     if ( meFailed ) {
    //         setValidSession( false )
    //         navigate( "/" )
    //     }
    // }, [ meFailed ])

    // useEffect(() => {
    //     if ( sessionFailed ) {
    //         setValidSession( false )
    //         navigate( "/" )
    //     }
    // }, [ sessionFailed ])

    return (
        <div className="navigation">
            <Drawer
                className="drawer"
                anchor="left"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen( false )}>
                {me && (
                    <List>
                        <ListItem
                            button
                            onClick={() => navigateAndCloseDrawer( '/scores' )}>
                            <ListItemIcon>
                                <ViewIcon />
                            </ListItemIcon>
                            View Scores
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => navigateAndCloseDrawer( '/score' )}>
                            <ListItemIcon>
                                <CalculateIcon />
                            </ListItemIcon>
                            Calculate a Score
                        </ListItem>
                        {me?.level >= 2 && (
                            <ListItem
                                button
                                onClick={() => navigateAndCloseDrawer( '/instructions' )}>
                                <ListItemIcon>
                                    <InstructionsIcon />
                                </ListItemIcon>
                                Read Instructions
                            </ListItem>
                        )}
                        {me?.level >= 4 && (
                            <ListItem
                                button
                                onClick={() => navigateAndCloseDrawer( '/admin' )}>
                                <ListItemIcon>
                                    <AdminIcon />
                                </ListItemIcon>
                                Admin
                            </ListItem>
                        )}
                    </List>
                )}
            </Drawer>
            <header className="header">
                {/* <div>(727)-967-2171</div> */}
                <div className="contact-us">
                    <p className="italic">Contact Us:</p>
                    <p>(727) 992-1619</p>
                    <p>creditcars@tampabay.rr.com</p>
                </div>
                <div className="logo-container">
                    <CCSLogo />
                    <div className="dealer-title">
                        <h3>CAR SCORES</h3>
                        <p>BUY HERE PAY HERE UNDERWRITING PLATFORM</p>
                    </div>
                </div>

                <div className="welcome-container">
                    {me && (
                        <div className="welcome-user">
                            <h4>Welcome {me.organization}! </h4>
                        </div>
                    )}
                </div>
            </header>
            <Toolbar className="toolbar">
                <IconButton
                    className={`menu-button ${
                        ( me !== undefined && me.level <= 0 ) || me == undefined ?
                            'hidden' :
                            ''
                    }`}
                    disableRipple
                    onClick={() => setIsDrawerOpen( true )}>
                    <MenuIcon />
                </IconButton>
                {!me && (
                    <Button
                        className="login-button"
                        onClick={() => navigate( '/' )}>
                        Login
                    </Button>
                )}
                {me && (
                    <Button className="login-button" onClick={() => logout()}>
                        Logout
                    </Button>
                )}
            </Toolbar>
        </div>
    )
}

export default Navigation
