import { Option } from '../../datatypes/options'
import { api } from '../api'

const optionsApi = api.injectEndpoints({
    endpoints: ( builder ) => ({
        updateOptions: builder.mutation<any, { options: Option[], id: string }>({
            query: ({ options, id }) => {
                return {
                    url: `options/update/${id}`,
                    method: "PUT",
                    body: options,
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
        editOption: builder.mutation<any, { id: string, option: string, name: string, weight: number }>({
            query: ({ name, weight, id, option }) => {
                return {
                    url: `options/edit/${id}`,
                    method: "PATCH",
                    credentials: "include",
                    body: {
                        option_name: option,
                        display_name: name, 
                        value: weight
                    },
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
        deleteOption: builder.mutation<any, { name: string, id: string }>({
            query: ({ name, id }) => {
                return {
                    url: `options/delete/${id}`,
                    method: "DELETE",
                    credentials: "include",
                    body: {
                        name,
                    },
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
        addOption: builder.mutation<any, ({ category: string, name: string, weight: number, order: number })>({
            query: ({ category, name, weight, order }) => {
                return {
                    url: `options/add/${category}`,
                    body: {
                        display_name: name,
                        value: weight,
                        order
                    },
                    method: "POST",
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
    })
})

export const {
    useUpdateOptionsMutation,
    useDeleteOptionMutation,
    useAddOptionMutation,
    useEditOptionMutation
} = optionsApi