import './Register.scss'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextField, Button } from '@mui/material'
import { useCreateUserMutation } from '../../api/auth/api.auth'
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/dist/query'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'

export function invalidPassword ( password: string ) {
    // eslint-disable-next-line no-useless-escape
    const regex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
    const valid = regex.test( password )
    return !valid
}

function Register () {
    const navigate = useNavigate()

    const [ firstName, setFirstName ] = useState( '' )
    const [ lastName, setLastName ] = useState( '' )

    const [ email, setEmail ] = useState( '' )
    const [ org, setOrg ] = useState( '' )
    const [ phone, setPhone ] = useState( '' )
    const [ password, setPassword ] = useState( '' )
    const [ repeatPassword, setRepeatPassword ] = useState( '' )
    const [ createUser, createUserResult ] = useCreateUserMutation()

    useEffect(() => {
        //console.log( createUserResult )
        if ( createUserResult?.data ) {
            switch ( createUserResult.data.status ) {
            case -1:
                alert( 'Email address already in use.' )
                break
            case -2:
                alert( 'Something went wrong. Unable to create account.' )
                break
            case 0:
                if ( createUserResult.status === QueryStatus.fulfilled ) {
                    // alert( "" )
                    navigate( '/pending' )
                }
            }
        }
    }, [ createUserResult ])

    const handlePhoneChange = ( newValue: string ) => {
        setPhone( newValue )
    }

    function registerUser () {
        if ( !invalidPassword( password )) {
            if ( password === repeatPassword ) {
                if ( email.length > 3 ) {
                    if ( firstName.length > 0 && lastName.length > 0 ) {
                        if ( org.length > 0 ) {
                            const user = {
                                name: firstName.trim() + ' ' + lastName.trim(),
                                email: email.trim(),
                                password,
                                organization: org.trim(),
                                phone,
                                repeatPassword,
                            }
                            createUser( user )
                        }
                    }
                }
            }
        }
    }

    const nameRegex = new RegExp( /^\w+(?:\s+\w+)*$/ )
    function validateName ( val: string ) {
        return !nameRegex.test( val )
    }

    const emailRegex = new RegExp(
        /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i
    )
    function validateEmail ( val: string ) {
        return !emailRegex.test( val )
    }
    return (
        <div className="register">
            <h4>Create Account</h4>
            <div className="register-inputs">
                <div>
                    <TextField
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        value={firstName}
                        required
                        error={firstName.length < 3 || validateName( firstName )}
                        onChange={( e: any ) => setFirstName( e.target.value )}/>
                    <TextField
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        value={lastName}
                        required
                        error={
                            lastName.length < 3 ||
                            validateName( lastName ) ||
                            `${firstName} ${lastName}`.length > 50
                        }
                        onChange={( e: any ) => setLastName( e.target.value )}/>
                </div>
                <TextField
                    fullWidth
                    label="Organization"
                    variant="outlined"
                    value={org}
                    required
                    error={org.length < 3 || validateName( org )}
                    onChange={( e: any ) => setOrg( e.target.value )}/>
                <MuiTelInput
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    value={phone}
                    required
                    forceCallingCode
                    defaultCountry="US"
                    disableDropdown={true}
                    error={!matchIsValidTel( phone )}
                    onChange={handlePhoneChange}/>
                <TextField
                    fullWidth
                    label="Email address"
                    variant="outlined"
                    value={email}
                    required
                    error={email.length < 3 || validateEmail( email )}
                    onChange={( e: any ) => setEmail( e.target.value )}/>
                <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    value={password}
                    type="password"
                    required
                    error={invalidPassword( password )}
                    helperText="Minimum eight characters, at least one letter, one number, and one special character"
                    autoComplete="current-password"
                    onChange={( e: any ) => setPassword( e.target.value )}/>
                <TextField
                    fullWidth
                    label="Verify Password"
                    variant="outlined"
                    value={repeatPassword}
                    type="password"
                    required
                    error={repeatPassword !== password}
                    helperText="Passwords must match"
                    autoComplete="current-password"
                    onChange={( e: any ) => setRepeatPassword( e.target.value )}/>
            </div>
            <Button
                className="register-btn"
                variant="contained"
                onClick={() => registerUser()}>
                Create Account
            </Button>
            <Button className="login" onClick={() => navigate( '/' )}>
                Already have an account? Login
            </Button>
        </div>
    )
}

export default Register
