import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip } from "@mui/material"
import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"
import { useGetBuyerCategoriesQuery, useGetCosignerCategoriesQuery } from "../../api/categories/api.categories"
import { Category } from "../../datatypes/categories"
import { FullScoreCard, Score } from "../../datatypes/score"
import { Option } from "../../datatypes/options"
import BackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from "react-router-dom"

import "./Scorecard.scss"
import { Buyer } from "../../datatypes/buyer"
import { useAddScoreMutation } from "../../api/scores/api.scores"

function Scorecard () {
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    const [ addScore, scoreAddedResult ] = useAddScoreMutation()

    const [ selectedBuyerOptions, setSelectedBuyerOptions ] = useState({} as any )
    const [ selectedCosignerOptions, setSelectedCosignerOptions ] = useState({} as any )

    const [ initialBuyerStateHasBeenSet, setInitialBuyerStateHasBeenSet ] = useState( false )
    const [ initialCosignerStateHasBeenSet, setInitialCosignerStateHasBeenSet ] = useState( false )

    const [ cosignerScoreCard, setCosignerScoreCard ] = useState( null )
    const [ finalCosignerScore, setFinalCosignerScore ] = useState( 0 )

    const [ scoreIsSet, setScoreIsSet ] = useState( false )
    
    const navigate = useNavigate()

    const [ customerName, setCustomerName ] = useState( "" )
    const [ cosignerName, setCosignerName ] = useState( "" )
    const [ dealerName, setDealerName ] = useState( "" )
    const [ selectedBuyerType, setSelectedBuyerType ] = useState( Buyer.Unspecified )

    const selectBuyerOption = ( categoryId: string, e: any )  => {
        setSelectedBuyerOptions({ ...selectedBuyerOptions, [categoryId]: e.target.value })
        setScoreIsSet( false )
    }

    const selectCosignerOption = ( categoryId: string, e: any )  => {
        setSelectedCosignerOptions({ ...selectedCosignerOptions, [categoryId]: e.target.value })
        setScoreIsSet( false )
    }
    
    useEffect(() => {
        const initialBuyerState = {} as any
        if ( buyerCategories !== undefined ) {
            buyerCategories.forEach(( category: Category ) => {
                initialBuyerState[category._id] = 'SELECT'
                
            })
            setSelectedBuyerOptions( initialBuyerState )
            setInitialBuyerStateHasBeenSet( true )
        }
    
    }, [ buyerCategories ])

    useEffect(() => {
        const initialCosignerState = {} as any
        if ( cosignerCategories !== undefined ) {
            cosignerCategories.forEach(( category: Category ) => {
                const thisOption = category.options.find(( opt ) => opt.order === 1 )
                initialCosignerState[category._id] = 'SELECT'
                
            })
            setSelectedCosignerOptions( initialCosignerState )
            setInitialCosignerStateHasBeenSet( true )
        }
    
    }, [ cosignerCategories ])

    
    useEffect(() => {
        if ( scoreIsSet ) {
            if ( scoreAddedResult.isSuccess ) {
                // TODO: add to redux
                navigate( "/scores" )
            } else if ( scoreAddedResult.isError ) {

                alert( "Could not calculate score" )
            }
        }
    }, [ scoreIsSet, scoreAddedResult, navigate ])
    
    function scoreBuyer () {
        if ( customerName !== "" && dealerName !== "" ) {
            const scoreCard: any = []
            const result = Object.keys( selectedBuyerOptions ).map(( key ) => [ key, selectedBuyerOptions[key] ])
            let invalid = false
            result.forEach(( select ) => {
                if ( select[1] === "SELECT" ) {
                    invalid = true
                    return 
                }
                const category = buyerCategories?.find(( cat ) => cat._id === select[0])
                const option = category?.options.find(( opt ) => opt.display_name === select[1]) 
               
                scoreCard.push( new Score( category?.display_name ?? "", option?.display_name ?? "", ( category?.weight ?? 0 ) * ( option?.value ?? 0 )))
            })
            if ( invalid ) {
                return
            }
            const totalScore = scoreCard.reduce(( prev: any, curr: Score ) => {
                return { score: prev.score + curr.score }
            })
            const fullScoreCard: FullScoreCard = {
                customerName,
                dealerName,
                buyerOptions: scoreCard,
                totalBuyerScore: totalScore.score,
                date: new Date()
            }
            
            
            if ( cosignerName !== "" ) {
                fullScoreCard.cosignerName = cosignerName
                fullScoreCard.cosignerOptions = cosignerScoreCard
                fullScoreCard.totalCosignerScore = finalCosignerScore
            }
            addScore( fullScoreCard )
            //console.log( fullScoreCard )
            setScoreIsSet( true ) 
        }
    }

    function scoreCosigner () {
        if ( cosignerName !== "" ) {
            const scoreCard: any = []
            const result = Object.keys( selectedCosignerOptions ).map(( key ) => [ key, selectedCosignerOptions[key] ])
            let invalid = false
            result.forEach(( select ) => {
                if ( select[1] === "SELECT" ) {
                    invalid = true
                    return 
                }
                const category = cosignerCategories?.find(( cat ) => cat._id === select[0])
                const option = category?.options.find(( opt ) => opt.display_name === select[1]) 
                scoreCard.push( new Score( category?.display_name ?? "", option?.display_name ?? "", ( category?.weight ?? 0 ) * ( option?.value ?? 0 )))
            })
            if ( invalid ) {
                return
            }
            const totalScore = scoreCard.reduce(( prev: any, curr: Score ) => {
                return { score: prev.score + curr.score }
            })
            setFinalCosignerScore( totalScore.score )
            setCosignerScoreCard( scoreCard )
            setSelectedBuyerType( Buyer.Buyer )
        }
    }

    return (
        <div className="scorecard-scorer">
            {selectedBuyerType === Buyer.Unspecified && 
                ( <div className="buyer-type-selector">
                    <Button variant="contained" onClick={() => setSelectedBuyerType( Buyer.Cosigned )}>Cosigner & Buyer</Button>
                    <Button variant="contained" onClick={() => setSelectedBuyerType( Buyer.Buyer )}>Buyer without Cosigner</Button>
                </div> )}
            {selectedBuyerType !== Buyer.Unspecified && 
                ( <>
                    <div>
                        <IconButton className="back-button" onClick={() => setSelectedBuyerType( Buyer.Unspecified )}>
                            <BackIcon></BackIcon>
                        </IconButton>
                    </div>
                    {selectedBuyerType === Buyer.Cosigned && 
                        ( <>
                            <h2>Score Cosigner</h2>
                            {cosignerCategories !== undefined && initialCosignerStateHasBeenSet === true &&
                                ( <div className="cosigner-card"> 
                                    <div className="cosigner-info">
                                        <TextField id="cosigner-name-input" 
                                                   label="Cosigner Name" 
                                                   required
                                                   error={cosignerName === ""}
                                                   value={cosignerName}
                                                   onChange={( e ) => setCosignerName( e.target.value )} />
                                        <TextField id="dealer-name-input" 
                                                   label="Salesperson Name" 
                                                   required
                                                   error={dealerName === ""}
                                                   value={dealerName}
                                                   onChange={( e ) => setDealerName( e.target.value )} />
                                    </div>
                                    <div className="options-section">
                                        {cosignerCategories.map(( category: Category ) => (
                                            <FormControl fullWidth key={category._id} className="option-form-control" error={selectedCosignerOptions[category._id] === 'SELECT'}>
                                                <InputLabel id={`select-option-label-${category._id}`}>{category.display_name}</InputLabel>
                                                <Select label={category.display_name}
                                                        labelId={`select-option-label-${category._id}`}
                                                        value={selectedCosignerOptions[category._id]}
                                                        autoWidth={true}
                                                        onChange={( e ) => selectCosignerOption( category._id, e )}>
                                                    <MenuItem className="select-option" defaultChecked value={"SELECT"}>Select...</MenuItem>    
                                                    {category.options.map(( option: Option ) => (
                                                        <MenuItem className="select-option" key={option.display_name} value={option.display_name}>{option.display_name}</MenuItem>    
                                                    ))}
                                                    
                                                </Select>
                                            </FormControl>
                                        ))}
                                    </div>
                                    <Button className="finish-score-button" 
                                            variant="contained"
                                            onClick={scoreCosigner}>Finish</Button>
                                </div> )}
                        </> )}
                    {selectedBuyerType === Buyer.Buyer &&
                        ( <>
                            <h2>Score Buyer</h2>
                            {buyerCategories !== undefined && initialBuyerStateHasBeenSet === true &&
                            ( <div className="customer-card"> 
                                <div className="customer-info">
                                    <TextField id="customer-name-input" 
                                               label="Customer Name" 
                                               required
                                               error={customerName === ""}
                                               value={customerName}
                                               onChange={( e ) => setCustomerName( e.target.value )} />
                                    {cosignerName === "" &&
                                                   ( <TextField id="dealer-name-input" 
                                                                label="Dealer Name" 
                                                                required
                                                                error={dealerName === ""}
                                                                value={dealerName}
                                                                onChange={( e ) => setDealerName( e.target.value )} /> )}
                                </div>
                                <div className="options-section">                
                                    {buyerCategories.map(( category: Category ) => (
                                        <FormControl key={category._id} className="option-form-control" error={selectedBuyerOptions[category._id] === 'SELECT'}>
                                            <InputLabel id={`select-option-label-${category._id}`}>{category.display_name}</InputLabel>
                                            <Select label={category.display_name}
                                                    labelId={`select-option-label-${category._id}`}
                                                    autoWidth={true}
                                                    value={selectedBuyerOptions[category._id]}
                                                    onChange={( e ) => selectBuyerOption( category._id, e )}>
                                                <MenuItem className="select-option" value={"SELECT"}>Select...</MenuItem>    

                                                {category.options.map(( option: Option ) => (
                                                    <MenuItem className="select-option" key={option.display_name} value={option.display_name}>{option.display_name}</MenuItem>    
                                                ))}
                                                
                                            </Select>
                                        </FormControl>
                                    ))}
                                </div>
                                <Button className="finish-score-button" 
                                        variant="contained"
                                        onClick={scoreBuyer}>Finish</Button>
                            </div> )}
                        </> )}
                </> )}
        </div>
    )
}

export default Scorecard