import './InstructionsManagement.scss'
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { HostInfo } from "../../../api/host"

function InstructionsManagement () {
    const host = new HostInfo()
    const url = host.GetAPIHost()
    const inputFile = useRef<HTMLInputElement | null>( null )
    const [ selectedFile, setSelectedFile ] = useState({} as any )
    
    function onFileChange ( event: any ) {
        const file = event.target.files[0]
        const splitName = file.name.split( '.' )
        const ext = splitName[splitName.length - 1]
        if ( ext !== "pdf" ) {
            alert( "Must upload a .pdf, not a ." + ext )
        } else {
            setSelectedFile( event.target.files[0])
        }
    }

    function submit ( event: any ) {
        event.preventDefault()
        if ( selectedFile && selectedFile.name ) {
            const formData = new FormData()
            formData.append( 'uploadAdmin', selectedFile )

            axios.post( url + "/instructions", formData, { withCredentials: true })
                .then(( res ) => {
                    alert( "File uploaded successfully" )
                })
                .catch(( err ) => {
                    alert( "There was an error in uploading the PDF: " + err )
                })
        } else {
            alert( "Must select a valid PDF before submitting" )
        }
    }
    return (
        <div className="instructions-form">
            <h4>Upload a PDF to replace the instructions shown in the Instructions page.</h4>
            <form onSubmit={submit} encType="multipart/form-data" method="POST">
                <input type='file' id='file' name="uploadAdmin" ref={inputFile} onChange={onFileChange} required/><br/>
                <input type="submit" value="Submit"/> 
            </form>
        </div>
    )
}
export default InstructionsManagement