import { FullScoreCard, StoredFullScoreCard } from '../../datatypes/score'
import { Users } from '../../datatypes/users'
import { api } from '../api'

const usersApi = api.injectEndpoints({
    endpoints: ( builder ) => ({
        deleteUser: builder.mutation<any, { id: string }>({
            query: ({ id }) => {
                return {
                    url: `users/delete/${id}`,
                    method: "DELETE",
                    credentials: "include",
                }
            },
            invalidatesTags: [ "Users" ]
        }),
        getUsers: builder.query<Users, void>({
            query: () => {
                return {
                    url: 'users',
                    method: "GET",
                    credentials: "include",
                }
            },
            providesTags: [ "Users" ]
        }),
        editUser: builder.mutation<any, { level: number, enabled: boolean, id: string }>({
            query: ({ id, enabled, level }) => {
                return {
                    url: `users/edit/${id}`,
                    method: "PUT",
                    body: {
                        level,
                        enabled
                    },
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Users" ]
        })
    })
})

export const {
    useDeleteUserMutation,
    useGetUsersQuery,
    useEditUserMutation
} = usersApi