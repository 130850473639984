import { useEditCategoryMutation, useGetBuyerCategoriesQuery, useGetCosignerCategoriesQuery } from '../../../api/categories/api.categories'
import './OptionManagement.scss'
import { Box } from "grommet"

import { DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay } from '@dnd-kit/core'
import { arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Category } from '../../../datatypes/categories'
import { Option } from '../../../datatypes/options'

import EditIcon from "@mui/icons-material/Edit"
import DragIcon from "@mui/icons-material/Menu"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/AddCircle"


import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { forwardRef, useEffect, useState } from 'react'
import { Divider, List, ListItemButton, ListItemText, TextField, DialogContent, Container, IconButton, Dialog, DialogTitle, DialogActions, Button, Tabs, Tab, FormControl, InputLabel, Select } from '@mui/material'
import { useAddOptionMutation, useDeleteOptionMutation, useEditOptionMutation, useUpdateOptionsMutation } from '../../../api/options/api.options'
import { weightInvalid } from '../../../util'

export const Item = ( props: any ) => {
    const styles = {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        color: "black",
        fontWeight: "bold"
    }
    const { option, category } = props
    const [ editDialogOpen, setEditDialogOpen ] = useState( false )
    const [ deleteDialogOpen, setDeleteDialogOpen ] = useState( false )
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()


    function openDeleteDialog () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            const thisCategory = ( category.type === "buyer" ? buyerCategories : cosignerCategories ).find(( cat: Category ) => cat._id === category._id )
            if ( thisCategory !== undefined ) {
                const categoryHasAtLeastOneOption = thisCategory.options.length > 1
                if ( categoryHasAtLeastOneOption ) {
                    setDeleteDialogOpen( true )
                } else {
                    alert( "Category must have at least one option at all times." )
                }
            }
        }
    }
    
    return (
        
        <div style={styles}>
            <EditOptionDialog open={editDialogOpen} setOpen={setEditDialogOpen} category={category} option={option}/>
            <DeleteOptionDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} category={category} option={option}/>
            #{option.order}: {option.display_name}
            <div className="item-action-button-container">
                <IconButton onClick={() => setEditDialogOpen( true )}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={openDeleteDialog}>
                    <DeleteIcon/>
                </IconButton>
            </div>
        </div>
    )
}

function EditOptionDialog ( props: any ) {
    const { open, setOpen, category, option } = props
    const [ name, setName ] = useState( option.display_name )
    const [ weight, setWeight ] = useState( option.value )
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    const [ editOption, optionEditedResult ] = useEditOptionMutation()

    useEffect(() => {
        if ( open ) {
            setName( option.display_name )
            setWeight( option.value )
        }
    }, [ open ])

    const handleNameChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setName( event.target.value )
    }


    function editCategoryOption () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            const thisCategory = ( category.type === "buyer" ? buyerCategories : cosignerCategories ).find(( cat: Category ) => cat._id === category._id )
            if ( thisCategory !== undefined ) {
                const theseOptions = thisCategory.options
                if ( !weightInvalid( weight )) {
                    if ( !optionInvalid( name, theseOptions, option.display_name )) {
                        const weightNum = parseFloat( weight )
                        if ( !isNaN( weightNum )) {
                            editOption({ id: category._id, name: name.trim(), weight: weightNum, option: option.display_name })
                            handleClose()   
                        }
                    } else {
                        if ( name === "" ) {
                            alert( "Option name is empty. Please enter a name for the option." )
                        } else {
                            alert( "Option name is invalid. Please enter a option name that hasn't already been used for a " + category.display_name )
                        }
                    }
                }
            }
            
        } else {
            alert( "Option weight is invalid. Please enter a positive or negative number." )
        }
    }

    function handleClose () {
        setName( "" )
        setWeight( "" )
        setOpen( false )
    }
    return (
        <Dialog className="edit-dialog" open={open} onClose={() => setOpen( false )}>
            <DialogTitle>Edit Option</DialogTitle>
            <div className="edit-content">
                <TextField label="Option Name" variant="outlined" value={name} fullWidth error={optionInvalid( name, category.options, option.display_name )} onChange={handleNameChange}/>
                <TextField label="Value" variant="outlined" error={weightInvalid( weight )} value={weight} onChange={( e ) => setWeight( e.target.value )}/> 
            </div>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={editCategoryOption}>Update Option</Button>
            </DialogActions>
        </Dialog>
    )
}

function DeleteOptionDialog ( props: any ) {
    const { open, setOpen, category, option } = props

    const [ deleteOption, optionDeletedResult ] = useDeleteOptionMutation()
    const [ updateOptions, optionsUpdatedResult ] = useUpdateOptionsMutation()

    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    
    function deleteAndClose () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            deleteOption({ id: category._id, name: option.display_name })
            if ( category.type === "buyer" ) {
                const thisOptionGroup = buyerCategories.find(( cat: Category ) => cat._id === category._id )?.options
                const adjusted = thisOptionGroup?.filter(( opt ) => opt.display_name !== option.display_name )
                const newReorderedOptions = adjusted?.map(( cat: any, i ) => {
                    return { ...cat, order: i + 1 }
                })
                updateOptions({ options: newReorderedOptions ?? [], id: category._id })
            } else {
                const adjusted = cosignerCategories.filter(( cat ) => cat._id !== category._id )
                const newReorderedCategories = adjusted.map(( cat: any, i ) => {
                    return { ...cat, order: i + 1 }
                })
                // updateOptions( newReorderedCategories )
            }
            setOpen( false )
        }
    }
    return (
        <Dialog className="edit-dialog" open={open} onClose={() => setOpen( false )}>
            <DialogTitle>Delete Option</DialogTitle>
            <DialogContent>
                <h4>Are you sure you want to delete {option.display_name}?</h4>
                <h4>This will permanently delete this option!</h4>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={deleteAndClose}>Delete Option</Button>
            </DialogActions>
        </Dialog>
    )
}



function AddOptionDialog ( props: any ) {
    const { open, setOpen, category } = props

    const [ addOption, optionAddedResult ] = useAddOptionMutation()
    const [ name, setName ] = useState( "" )
    const [ weight, setWeight ] = useState( "" )
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()

    const handleNameChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setName( event.target.value )
    }

    const handleWeightChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setWeight( event.target.value )
    }

    function addAndClose () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            const thisCategory = ( category.type === "buyer" ? buyerCategories : cosignerCategories ).find(( cat: Category ) => cat._id === category._id )
            if ( thisCategory !== undefined ) {
                const theseOptions = thisCategory.options
                if ( !weightInvalid( weight )) {
                    if ( !optionInvalid( name, theseOptions )) {
                        const weightNum = parseFloat( weight )
                        if ( !isNaN( weightNum )) {
                            addOption({ category: category._id,
                                        name: name.trim(), 
                                        weight: weightNum, 
                                        order: theseOptions.length + 1 })
                            handleClose()   
                        }
                    } else {
                        if ( name === "" ) {
                            alert( "Option name is empty. Please enter a name for the option." )
                        } else {
                            alert( "Option name is invalid. Please enter an option name that hasn't already been used for " + category.display_name )
                        }
                    }
                } else {
                    alert( "Option weight is invalid. Please enter a positive or negative number." )
                }
            }
        }
    }

    function handleClose () {
        setName( "" )
        setWeight( "" )
        setOpen( false )
    }
  
    return (
        <Dialog className="edit-dialog add" open={open} onClose={handleClose}>
            <DialogTitle>Add New Option</DialogTitle>
            <DialogContent>
                <div className="edit-content">
                    <TextField fullWidth error={optionInvalid( name, category.options )} label="Option Name" variant="outlined" value={name} onChange={handleNameChange}/>
                    <TextField label="Value" variant="outlined" error={weightInvalid( weight )} value={weight} onChange={handleWeightChange}/> 
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={addAndClose}>Add Option</Button>
            </DialogActions>
        </Dialog>
    )
}

function optionInvalid ( option: string, options: Option[], currentOption?: string ) {
    
    if ( option !== "" ) {
        if ( option.trim() === currentOption ) {
            return false
        } else {
            const valueArr = options.map( function ( opt ){ 
                return opt.display_name 
            })
            const isDuplicate = valueArr.indexOf( option.trim()) !== - 1
            return isDuplicate
        }
    } else {
        return true
    }
}

function CardsWrapper ( props: any ) {
    return (
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem",
            height: "100%",
        }}>
            {props.children}
        </div>
    )
}


export function SortableItem ( props: any ) {
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id })
    
    const style = {
        transform: CSS.Transform.toString( transform ),
        transition,
        border: "2px solid lightgrey",
        borderRadius: "1vmin",
        backgroundColor: "white",
        display: "flex",
        padding: "0.5rem",
        margin: "0 0.5rem 0.5rem 0",
        maxWidth: "30vw",
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1
    }
    
    return (
        <div
        ref={setNodeRef}
        style={style}
        faded={isDragging.toString()}
        {...props}>
            <IconButton {...attributes} {...listeners} >
                <DragIcon/>
            </IconButton>
            <Item option={props.option} category={props.category}/>
        </div>
    )
}

function OptionManagement () {
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    const [ updateOptions, updateOptionsResult ] = useUpdateOptionsMutation()
    const [ newBuyerCategories, setNewBuyerCategories ] = useState<any>([])
    const [ newCosignerCategories, setNewCosignerCategories ] = useState<any>([])
    // buyer = 0, cosigner = 1
    const [ selectedType, setSelectedType ] = useState( 0 )
    const [ category, setCategory ] = useState( newBuyerCategories )


    const [ selectedCategory, setSelectedCategory ] = useState( 0 )
    const sensors = useSensors(
        useSensor( PointerSensor ),
        useSensor( KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )


    useEffect(() => {
        if ( buyerCategories !== undefined ) {
            setNewBuyerCategories( buyerCategories )
        }
    }, [ buyerCategories ])

    useEffect(() => {
        if ( cosignerCategories !== undefined ) {
            setNewCosignerCategories( cosignerCategories )
        }
    }, [ cosignerCategories ])

    useEffect(() => {
        if ( selectedType === 0 ) {
            if ( newBuyerCategories !== undefined ) {
                setCategory( newBuyerCategories )
            }
        }
    }, [ newBuyerCategories, selectedType ])

    useEffect(() => {
        if ( selectedType === 1 ) {
            if ( newCosignerCategories !== undefined ) {
                setCategory( newCosignerCategories )
            }
        }
    }, [ newCosignerCategories, selectedType ])

    function handleDragEnd ( event: any, id: string ) {
        const { active, over } = event
        
        if ( active.id !== over.id ) {
            if ( selectedType === 0 ) {
                try {
                    const newCategories = rearrangeCategory( id, active, over, newBuyerCategories )
                    updateOptions({ options: newCategories.find(( cat: Category ) => cat._id === id ).options, id })
                    setNewBuyerCategories( newCategories )
                }  catch ( err ) {
                    console.error( "could not update buyer option" )
                }
            } else {
                try {
                    const newCategories = rearrangeCategory( id, active, over, newCosignerCategories )
                    updateOptions({ options: newCategories.find(( cat: Category ) => cat._id === id ).options, id })
                    setNewCosignerCategories( newCategories )
                }  catch ( err ) {
                    console.error( "could not update buyer option" )
                }
            }
        }
    }

    function rearrangeCategory ( id: string, active: any, over: any, editingCategory: any ) {
        const thisCategory = editingCategory.find(( cat: Category ) => cat._id === id ).options
        const oldIndex = thisCategory.indexOf( thisCategory.find(( opt: any ) => opt.display_name === active.id ))
        const newIndex = thisCategory.indexOf( thisCategory.find(( opt: any ) => opt.display_name === over.id ))
        const newOptions = arrayMove( thisCategory, oldIndex, newIndex )
        const newReorderedOptions = newOptions.map(( opt: any, i ) => {
            return { ...opt, order: i + 1 }
        })

        const result = editingCategory.map(( el: any ) => el._id === id? { ...el, options: newReorderedOptions } : el )

        return result
    }

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedCategory( index )
    }

    const [ activeId, setActiveId ] = useState( null )

    const handleDragStart = ( event: any ) => {
        setActiveId( event.active.id )
    }

    
    function changeCategorySet ( index: number ) {
        setSelectedType( index )
        setCategory( index === 0 ? newBuyerCategories : newCosignerCategories )
        setSelectedCategory( 0 )
    }

    const [ addOptionOpen, setAddOptionOpen ] = useState( false )
      
    return (
        <div className="option-management">
            <div className="tab-list-container">
                <h3>Categories</h3>
                <div className="tab-buttons">
                    <Button className={selectedType === 0 ? "selected" : ""} onClick={() => changeCategorySet( 0 )}>
                        Buyer
                    </Button>
                    <Button className={selectedType === 1 ? "selected" : ""} onClick={() => changeCategorySet( 1 )}>
                        Cosigner
                    </Button>
                </div>
                {category !== undefined && category.length > 0 &&
                
                  ( <List component="nav" aria-label="main mailbox folders">

                      {category.map(( cat: Category, i: number ) => (
                          <ListItemButton
                  key={i}
                  selected={selectedCategory === i}
                  onClick={( event ) => handleListItemClick( event, i )}>
                  
                              <ListItemText primary={cat.display_name} />
                          </ListItemButton>
                      ))}
                  </List> )}
            </div>
            <Divider orientation='vertical' flexItem />

            <Container className="option-reordering">
                {category !== undefined && category.length > 0 &&
                ( <div className="buyer-reorder">
                    <AddOptionDialog open={addOptionOpen} setOpen={setAddOptionOpen} category={category[selectedCategory]} />

                    <div className="option-actions">
                        <h3>Options</h3>
                        <IconButton disableRipple onClick={() => setAddOptionOpen( true )}>
                            <AddIcon/>
                        </IconButton>
                    </div>     
                    
                    <DndContext sensors={sensors} 
                                collisionDetection={closestCenter} 
                                onDragStart={handleDragStart}
                                onDragEnd={( e ) => handleDragEnd( e, category[selectedCategory]._id )}>
                             
                        <SortableContext  items={category[selectedCategory].options.map(( a: any ) => a.display_name )} strategy={verticalListSortingStrategy}>
                            <CardsWrapper >
                                {category[selectedCategory].options.map(( opt: any, i: number ) => (
                        
                                    <SortableItem key={opt.display_name} id={opt.display_name} option={opt} category={category[selectedCategory]} index={i}/>
                                ))}
                            </CardsWrapper>
                        </SortableContext>
                    </DndContext> 
                </div> )}
      
             
           

            </Container>
                    
            
        </div>
    )
}

export default OptionManagement