import './Pending.scss'

function Pending () {
    return (
        <div className="pending">
            <h3>Account Registration Request Received</h3>
            <p>Your account is currently awaiting approval. You will not be able to log in until your account is approved. Please try logging in again later.</p>
        </div>
    )
}

export default Pending