import Button from "@mui/material/Button"
import { useRef } from "react"
import ReactToPrint from "react-to-print"
import './DeclineLetter.scss'
type DeclineLetterProps = {
    name: string,
    date: Date
}

function DeclineLetter ( props: DeclineLetterProps ) {
    const printableScoreAreaRef = useRef( null )
    const { name, date } = props
    const getPageMargins = () => {
        return `@page { margin: 4vw 4vw 4vw 4vw !important; }`
    }
    function formatDate ( date: Date ) {
        const dtFormat = Intl.DateTimeFormat( 'en-US', {
            dateStyle: "short"
        })
        const stringToDate = new Date( date )
        return dtFormat.format( stringToDate )
    }


    return (
        <div className="decline-letter-container">
            <ReactToPrint
                        trigger={() => <Button variant="contained" className="print-btn">Decline Letter</Button>}
                        content={() => printableScoreAreaRef.current}
                        documentTitle={`CreditCarsSales-${name}-${date}`}/>
            <div ref={printableScoreAreaRef}>
                <style>{getPageMargins()}</style>
                <div className="decline-letter">
                    <p>{name}</p>
                    <p>&nbsp;&nbsp;&nbsp;</p>
                    <p>, FL</p>
                    <p>Re: Loan Application {formatDate( date )}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;Dealer:</p>
                    <div className="dealer-address">
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Credit Cars Sales, LLC</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12711 US Hwy 19</p>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hudson, FL 34667</p>
                    </div>
                    <p>&nbsp;&nbsp;&nbsp;</p>
                    <p>Dear {name},</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;We are in receipt of your recent credit application, submitted in connection with 
                        an intended installment purchase of a vehicle from Credit Cars Sales, Llc.. We have given your application careful consideration.
                        However, we will not be able to open an account for you at this time.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;Our decision was based in whole or in part on information obtained from the consumer reporting agency 
                        identified below. If you wish to know the specific reason why your request for credit has not been approved, please write or call use within sixty (60) days.
                        It is your right to have a written confirmation of these reasons within thirty (30) days of a written request for such information.
                    </p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;The consumer reporting agency identified above is:</p>
                    <div className="reporting-agencies">
                        <div className="agency">
                            <p>Equifax Credit Information Services</p>
                            <p>P.O Box 740241</p>
                            <p>Atlanta, GA 30374</p>
                            <p>(800) 685-1111</p>
                        </div>
                        <div className="agency">
                            <p>Transunion</p>
                            <p>P.O Box 2000</p>
                            <p>Chester, PA 19022-2000</p>
                            <p>(800) 916-8800</p>
                        </div>
                        <div className="agency">
                            <p>Experian</p>
                            <p>P.O Box 2104</p>
                            <p>Allen, TX 75013-2104</p>
                            <p>(888) 397-3742</p>
                        </div>
                    </div>
                    <p>&nbsp;&nbsp;&nbsp;</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race,
                        color, religion, national origin, sex, marital status, age (provided that the applicant has the capacity to contract); because all or part of the applicant&#39s income
                        derives from any public assistance program; or because the applicant has in good faith exercised any right under the Consumer Credit Protection Act. The Federal Agency 
                        that administers compliance with this law concerning this creditor is the Federal Trade Commission, Equal Credit Opportunity, Washington,D.C. 20580 
                    </p>
                    <p>&nbsp;&nbsp;&nbsp;</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;Sincerely,</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;Credit Cars Sales, Llc.</p>

                </div>         
              
            </div>
        </div>
    )
}
export default DeclineLetter