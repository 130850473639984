import './Instructions.scss'
import { HostInfo } from '../../api/host'

function Instructions () {
    const host = new HostInfo()
    const uri = host.GetAPIHost()
    return (
        <div className="instructions-container">
            <object data={`${uri}/instructions`} name="Instructions" type="application/pdf" width="100%" height="100%"/>
        </div>
    )
}
export default Instructions