import { environment } from './environments/environment'

export class HostInfo {
    GetHost (): string {
        return environment.GetHost()
    }

    GetAPIHost (): string {
        return environment.GetAPIHost()
    }

    GetUserAuthHost (): string {
        return environment.GetUserAuthHost()
    }
}