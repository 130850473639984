import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import customFetchBase from './customFetchBase'
import { HostInfo } from './host'

function baseUrl (): string {
    const host = new HostInfo()
    const hostUrl = host.GetAPIHost()
    const url = hostUrl + "/"
    return url
}

export const api = createApi({
    reducerPath: "api",
    baseQuery: customFetchBase,
    tagTypes: [ "Scores", "Buyer Options", "Cosigner Options", "Users", "User" ],
    endpoints: () => ({}),
})