import { Tabs, Tab, Container } from '@mui/material'
import { useEffect, useState } from 'react'
import './Admin.scss'
import OptionManagement from './OptionManagement/OptionManagement'
import CategoryManagement from './CategoryManagement/CategoryManagement'
import UserManagement from './UserManagement/UserManagement'
import { useGetMeQuery } from '../../api/auth/api.auth'
import { Navigate, useNavigate } from 'react-router-dom'
import InstructionsManagement from './InstructionsManagement/InstructionsManagement'


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function TabPanel ( props: TabPanelProps ) {
    const { children, value, index, ...other } = props
  
    return (
        <div
        role="tabpanel"
        className={`tab-panel ${value !== index ? "hidden" : ""}`}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
            {value === index && (
                <Container>
                    {children}
                </Container>
            )}
        </div>
    )
}

function Admin () {
    const navigate = useNavigate()

    const [ selectedTab, setSelectedTab ] = useState( 0 )
    const { data: me, isError: meFailed } = useGetMeQuery()

    useEffect(() => {
        if ( me ) {
            if ( me.level < 4 ) {
                navigate( "/score" )
            }
        }

        if ( meFailed ) {
            navigate( "/" )
        }
    })

    const handleTabChange = ( event: React.SyntheticEvent, newValue: number ) => {
        setSelectedTab( newValue )
    }

    return (
        <div className='admin'>
            {me !== undefined && me.level >= 4 &&
            ( <div className="tabs-container">
                <Tabs
                orientation="vertical"
                variant="scrollable"
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}>
                    <Tab label="Manage Users" />
                    <Tab label="Manage Categories" />
                    <Tab label="Manage Options" />
                    <Tab label="Manage Instructions" />
                </Tabs>
                <TabPanel value={selectedTab} index={0}>
                    <UserManagement/>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <CategoryManagement/>
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                    <OptionManagement/>
                </TabPanel>
                <TabPanel value={selectedTab} index={3}>
                    <InstructionsManagement/>
                </TabPanel>
            </div> )            }
        </div>
    )
}

export default Admin