export const environment = {
    production: true,
    GetHost (): string {
        return "localhost"
    },
    GetAPIHost (): any {
        return process.env.REACT_APP_API_URL ?? "https://localhost:8080/api"
    },
    GetUserAuthHost (): string {
        return "http://localhost:8080"
    }
}