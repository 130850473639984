import { LoginData, NewUser, Session, User } from '../../datatypes/user'
import { api } from '../api'

const authApi = api.injectEndpoints({
    endpoints: ( builder ) => ({
        createUser: builder.mutation<any, NewUser>({
            query: ( entry ) => {
                return {
                    url: `users`,
                    method: "POST",
                    body: entry,
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Users" ]
        }),
        login: builder.mutation<{ accessToken: string }, LoginData>({
            query: ( creds ) => {
                return {
                    url: `sessions`,
                    method: "POST",
                    body: creds,
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "User" ]
        }),
        logout: builder.mutation<Session, void>({
            query: () => {
                return {
                    url: `sessions`,
                    method: "DELETE",
                    credentials: "include",
                }
            },
            invalidatesTags: [ "User" ]
        }),
        getMe: builder.query<User, void>({
            query: () => {
                return {
                    url: `me`,
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            providesTags: [ "User" ]
        }),
        renew: builder.query<Session, void>({
            query: () => {
                return {
                    url: `/sessions`,
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
        }),
        passwordReset: builder.mutation<void, string>({
            query: ( email ) => {
                return {
                    url: `/forgot-password`,
                    method: "POST",
                    body: {
                        email
                    }
                }
            }
        }),
        validateResetToken: builder.query<{ email: string }, string>({
            query: ( token ) => {
                return {
                    url: `/reset`,
                    method: "POST",
                    body: {
                        token
                    }
                }
            }
        }),
        resetPassword: builder.mutation<void, { email: string, password: string, confirmPassword: string, token: string }>({
            query: ({ email, password, confirmPassword, token }) => {
                return {
                    url: `/reset-password`,
                    method: "POST",
                    body: {
                        email,
                        password,
                        token,
                        confirmPassword
                    }
                }
            }
        })
    })
})

export const {
    useCreateUserMutation,
    useLoginMutation,
    useGetMeQuery,
    useLogoutMutation,
    useRenewQuery,
    usePasswordResetMutation,
    useValidateResetTokenQuery,
    useResetPasswordMutation
} = authApi