export class Score {
    public category: string
    public value: string
    public score: number
    constructor ( category: string, value: string, score: number ) {
        this.category =  category
        this.value = value
        this.score = score
    }
}
export type FullScoreCard = {
    customerName: string,
    dealerName: string,
    cosignerName?: string,
    totalBuyerScore: number,
    totalCosignerScore?: number,
    cosignerOptions?: any,
    buyerOptions: any,
    date: Date
}

export type ScoreID = {
    _id: string,
    organization: string
}

export type StoredFullScoreCard = ScoreID & FullScoreCard