import { Category } from '../../datatypes/categories'
import { api } from '../api'

const categoriesApi = api.injectEndpoints({
    endpoints: ( builder ) => ({
        getBuyerCategories: builder.query<Category[], void>({
            query: () => {
                return {
                    url: 'categories/buyer',
                    credentials: "include",
                }
            },
            
            providesTags: [ "Buyer Options" ]
        }),
        getCosignerCategories: builder.query<Category[], void>({
            query: () => {
                return {
                    url: 'categories/cosigner',
                    credentials: "include",
                }
            },
            providesTags: [ "Cosigner Options" ]
        }),
        updateCategories: builder.mutation<{ updated: number }, Category[]>({
            query: ( entry ) => {
                return {
                    url: `categories/update`,
                    method: "PUT",
                    credentials: "include",
                    body: entry,
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
        deleteCategory: builder.mutation<any, string>({
            query: ( id ) => {
                return {
                    url: `categories/delete/${id}`,
                    method: "DELETE",
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
        addCategory: builder.mutation<any, ({ type: string, name: string, weight: number, order: number })>({
            query: ({ type, name, weight, order }) => {
                return {
                    url: `categories/add`,
                    body: {
                        type,
                        display_name: name,
                        weight,
                        options: [
                            { display_name: 'N/A', order: 1, value: 0 }
                        ],
                        order
                    },
                    method: "POST",
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
        editCategory: builder.mutation<any, ({ id: string, name: string, weight: number })>({
            query: ({  id, name, weight }) => {
                return {
                    url: `categories/edit/${id}`,
                    body: {
                        display_name: name,
                        weight,
                    },
                    method: "PATCH",
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Buyer Options", "Cosigner Options" ]
        }),
    })
})

export const {
    useGetBuyerCategoriesQuery,
    useGetCosignerCategoriesQuery,
    useUpdateCategoriesMutation,
    useDeleteCategoryMutation,
    useAddCategoryMutation,
    useEditCategoryMutation
} = categoriesApi