import './App.scss'
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom'
import Navigation from './components/Navigation/Navigation'
import Scorecard from './views/Scorecard/Scorecard'
import ViewScores from './views/ViewScores/ViewScores'
import Admin from './views/Admin/Admin'
import Login from './views/Login/Login'
import Register from './views/Register/Register'
import { useRenewQuery } from './api/auth/api.auth'
import ForgotPassword from './views/ForgotPassword/ForgotPassword'
import Pending from './views/Pending/Pending'
import ResetPassword from './views/ResetPassword/ResetPassword'
import Instructions from './views/Instructions/Instructions'

function App () { 
        
    return (
        <div className="App">
            <Router>
                <Navigation/>
                <Routes>
                    <Route path="/score" element={<Scorecard/>} />
                    <Route path="/scores" element={<ViewScores/>} />
                    <Route path="/instructions" element={<Instructions/>} />
                    <Route path="/admin" element={<Admin/>} />
                    <Route path="/" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/reset" element={<ForgotPassword/>}/>
                    <Route path="/reset/:token" element={<ResetPassword/>}/>

                    <Route path="/pending" element={<Pending/>}/>
                </Routes>
            </Router>
        </div>
    )
}

export default App
