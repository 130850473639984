import { FullScoreCard, StoredFullScoreCard } from '../../datatypes/score'
import { api } from '../api'

const scoreApi = api.injectEndpoints({
    endpoints: ( builder ) => ({
        addScore: builder.mutation<any, FullScoreCard>({
            query: ( entry ) => {
                return {
                    url: `scores/add`,
                    method: "POST",
                    body: entry,
                    credentials: "include",
                    headers: {
                        'Content-Type': "application/json"
                    }
                }
            },
            invalidatesTags: [ "Scores" ]
        }),
        getScores: builder.query<StoredFullScoreCard[], void>({
            query: () => {
                return {
                    url: 'scores',
                    credentials: "include",
                }
            },
            providesTags: [ "Scores" ]
        }),
        getScore: builder.query<StoredFullScoreCard, string>({
            query: ( id ) => {
                return {
                    url: `scores/${id}`,
                    credentials: "include",
                }
            }
        })
    })
})

export const {
    useAddScoreMutation,
    useGetScoresQuery,
    useGetScoreQuery
} = scoreApi