import { Container, TextField, Button } from '@mui/material'
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/dist/query'
import { off } from 'process'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { setToken } from '../../api/auth'
import { useGetMeQuery, useLoginMutation } from '../../api/auth/api.auth'
import './Login.scss'

function Login () {
    const navigate = useNavigate()

    const [ email, setEmail ] = useState( "" )
    const [ password, setPassword ] = useState( "" )
    const [ canGetMe, setCanGetMe ] = useState( false )
    const [ loginRequest, loginRequestResult ] = useLoginMutation()
    const { data: me } = useGetMeQuery( undefined, { skip: !canGetMe })
    const dispatch = useDispatch()
    
    function login () {
        if ( email === "" ) {
            alert( "Email address is empty. Please enter the email address associated with your Car Scores account." )
        } else if ( password === "" ) {
            alert( "Password is empty. Please enter the password associated with your Car Scores account." )
        } else {
            loginRequest({ email, password })
        }
    }

    function hitEnter ( e: any ) {
        if ( e.key === 'Enter' || e.code === "Enter" ) {
            login()
        }
    }

    useEffect(() => {
        if ( me ) {
            //console.log( "got me data" )
            navigate( "/score" )
        }
    }, [ me ])

    useEffect(() => {
        if ( loginRequestResult?.error ) {
            //console.log( loginRequestResult.error )
            if ( typeof loginRequestResult.error === 'object' && loginRequestResult.error !== null ) {
                if ( 'data' in ( loginRequestResult.error as FetchBaseQueryError )) {
                    const error = ( loginRequestResult.error as FetchBaseQueryError ).data as any
                    if ( error ) {
                        if ( typeof error === 'object' && error !== null ) {
                            if ( 'status' in error ) {
                                if ( error.status == 3 ) {
                                    alert( "There was an error logging in." )
                                } else if ( error.status == 1 ) {
                                    alert( "Invalid username or password." )
                                } else if ( error.status == 0 && 'left' in error ) {
                                    alert( `Could not log in. You have ${error.left} tries left before your account is locked out.` )
                                } else if ( error.status == 2 ) {
                                    alert( "Your account has been locked out. Please reset your password or contact an Administrator." )
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if ( loginRequestResult.status === QueryStatus.fulfilled ) {
                if ( loginRequestResult.data.accessToken ) {
                    setCanGetMe( true )
                    // dispatch( setToken( loginRequestResult.data.accessToken ))
                    localStorage.setItem( "logged in", "true" )
                }
            }
        }
    }, [ loginRequestResult ])

    return (
        <div className="login">
            <h4>Customer Login</h4>
            <div className="login-inputs">
                <TextField fullWidth 
                           label="Email address" 
                           variant="outlined" 
                           value={email} 
                           onChange={( e: any ) => setEmail( e.target.value )}/>
                <TextField fullWidth 
                           label="Password" 
                           variant="outlined" 
                           value={password} 
                           type="password"
                           autoComplete="current-password"
                           onKeyPress={( e ) => hitEnter( e )}
                           onChange={( e: any ) => setPassword( e.target.value )}/>
            </div>
            <Button className="login-btn" variant="contained" onClick={() => login()}>Login</Button>
            <Button className="register-btn" onClick={() => navigate( "/reset" )}>Forgot Password?</Button>
            <Button className="register-btn" onClick={() => navigate( "/register" )}>New User? Register here</Button>
        </div>
    )
}

export default Login