import { Container, TextField, Button } from '@mui/material'
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetMeQuery, useLoginMutation, useResetPasswordMutation, useValidateResetTokenQuery } from '../../api/auth/api.auth'
import { invalidPassword } from '../Register/Register'
import './ResetPassword.scss'

function ResetPassword () {
    const { token } = useParams()
    const navigate = useNavigate()

    const [ email, setEmail ] = useState( "" )
    const [ password, setPassword ] = useState( "" )
    const [ confirmPassword, setConfirmPassword ] = useState( "" )

    const { isSuccess: validationComplete, isError: validationFailed, data: validation } = useValidateResetTokenQuery( token ?? "", { skip: token == undefined })
    const [ resetRequest, resetRequestResult ] = useResetPasswordMutation()
    const [ failureMessage, setFailureMessage ] = useState( "" )
    const [ successMessage, setSuccessMessage ] = useState( "" )


    useEffect(() => {
        if ( validationFailed ) {
            setFailureMessage( "Password reset link is invalid. It may have expired. Please create a new password reset request." )
        }
    }, [ validationFailed ])
    

    function reset () {
        if ( email === "" ) {
            alert( "Email address is empty. Please enter the email address associated with your Car Scores account." )
        } else if ( password === "" ) {
            alert( "Password is empty. Please enter the password associated with your Car Scores account." )
        } else if ( confirmPassword === "" ) { 
            alert( "Password confirmation is empty. Please enter your desired password again." )
        } else if ( password !== password ) {
            alert( "Passwords do not match. Please make sure your passwords match." )
        } else {
            resetRequest({ email, password, token: token ?? "", confirmPassword })
        }

    }

    function hitEnter ( e: any ) {
        if ( e.key === 'Enter' || e.code === "Enter" ) {
            reset()
        }
    }

    useEffect(() => {
        if ( resetRequestResult?.error ) {
            if ( resetRequestResult.error ) {
                alert( "Error logging in." )
            }
        } else {
            if ( resetRequestResult.status === QueryStatus.fulfilled ) {
                if ( resetRequestResult.data ) {
                    setSuccessMessage( "Password successfully changed. Redirecting to login..." )
                    setTimeout(() => {
                        navigate( "/" )
                    }, 4000 )
                }
            }
        }
    }, [ resetRequestResult ])

    return (
        <div className="reset">
            {failureMessage !== "" && successMessage === "" &&
                <h3 className="reset-failure">{failureMessage}</h3>}
            {successMessage !== "" && failureMessage === "" && 
                <h3 className="reset-failure">{successMessage}</h3>}
            {failureMessage === "" && validationComplete && successMessage === "" &&
                ( <div className="reset-pwd">
                    <h4>Reset Password</h4>
                    <div className="login-inputs">
                        <TextField fullWidth 
                                   label="Email address" 
                                   variant="outlined" 
                                   value={email} 
                                   onChange={( e: any ) => setEmail( e.target.value )}/>
                        <TextField fullWidth 
                                   label="Password" 
                                   variant="outlined" 
                                   value={password} 
                                   type="password"
                                   required
                                   error={invalidPassword( password )}
                                   helperText="Minimum eight characters, at least one letter, one number, and one special character"
                                   autoComplete="current-password"
                                   onChange={( e: any ) => setPassword( e.target.value )}/>
                        <TextField fullWidth 
                                   label="Verify Password" 
                                   variant="outlined" 
                                   value={confirmPassword} 
                                   type="password"
                                   required
                                   error={( confirmPassword !== password ) }
                                   helperText="Passwords must match"
                                   autoComplete="current-password"
                                   onChange={( e: any ) => setConfirmPassword( e.target.value )}/>
                    </div>
                    <Button className="reset-btn" variant="contained" onClick={() => reset()}>Reset Password</Button>
                </div> )}
            <Button className="login-btn" onClick={() => navigate( "/" )}>Login Instead?</Button>

        </div>
    )
}

export default ResetPassword