import { Grid, List, ListItemButton, ListItemText, Chip, Divider, IconButton, Button } from "@mui/material"
import { useGetScoreQuery, useGetScoresQuery } from "../../api/scores/api.scores"
import "./ViewScores.scss"
import PrintIcon from "@mui/icons-material/Print"
import NoCrashIcon from "@mui/icons-material/NoCrash"
import { useState, useEffect, useRef } from "react"
import ReactToPrint from 'react-to-print'
import CCSLogo from '../../assets/ccs.png'
import { useNavigate } from "react-router-dom"
import { useGetMeQuery } from "../../api/auth/api.auth"
import DeclineLetter from "./DeclineLetter/DeclineLetter"


function ViewScores () {
    const navigate = useNavigate()

    const { data: me, isError: meFailed } = useGetMeQuery()
    const [ selectedScoreId, setSelectedScoreId ] = useState( "" )


    const { data: scores } = useGetScoresQuery()
    const { data: selectedScore } = useGetScoreQuery( selectedScoreId, { skip: selectedScoreId.length === 0 })

    const printableScoreAreaRef = useRef( null )


    function handleListItemClick ( e: any, id: string ) {
        setSelectedScoreId( id )
    }

    function formatDate ( date: Date ) {
        const dtFormat = Intl.DateTimeFormat( 'en-US', {
            timeStyle: "short",
            hourCycle: "h12",
            dateStyle: "short"
        })
        const stringToDate = new Date( date )
        return dtFormat.format( stringToDate )
    }

    useEffect(() => {
        if ( scores !== undefined && scores.length > 0 ) {
            setSelectedScoreId( scores[0]._id )
        }
    }, [ scores ])

    function goToCreditCarsSales () {
        window.open( "https://creditcarssales.com/inventory", "_blank" )
    }


    const getPageMargins = () => {
        return `@page { margin: 4vw 4vw 4vw 4vw !important; }`
    }

    return (
        <Grid container className="view-scores" sx={{ justifyContent: scores !== undefined && scores?.length < 1 ? 'center' : 'flex-start' }}>
            {scores !== undefined && scores?.length < 1 &&
                ( <div>
                    <h3>No scores have been created yet. Calculate a score to see it here.</h3></div> )}
            {scores !== undefined && scores?.length > 0 &&
                ( <>
                    <Grid container item xs className="score-list-container">
                        <List component="div" 
                              className="score-list" 
                              aria-label="score-list">
                            {scores?.map(( score, i ) => (
                                <ListItemButton
                                key={score._id}
                                selected={selectedScoreId === score._id}
                                onClick={( event ) => handleListItemClick( event, score._id )}>
                                    {/* <ListItemIcon>
                                    <CheckIcon />
                                </ListItemIcon> */}
                                    <ListItemText primary={`${score.customerName} ${formatDate( score.date )}`} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Grid>
            
            
                    <Grid container item xs={10} className="score-card-viewer">
                        <ReactToPrint
                        trigger={() => <IconButton className="print-btn"><PrintIcon/></IconButton>}
                        content={() => printableScoreAreaRef.current}
                        documentTitle={`CreditCarsSales-${selectedScore?.customerName}-${selectedScore?.date}`}/>
                        <div ref={printableScoreAreaRef}>
                            <style>{getPageMargins()}</style>
                            {me?.level !== 2 &&
                                ( <div className="print-header">
                                    <img src={CCSLogo} className="print-logo"/>
                                    <h3 className="dealer-title">CREDIT CARS SALES, LLC.</h3>
                            
                                </div> )                            }
                            {selectedScore !== undefined && 
                                ( <div className="scorecard" >
                                    <div className="select-vehicle-container">
                                        <Button variant="contained" className="select-vehicle-btn" onClick={goToCreditCarsSales}>
                                            <NoCrashIcon className="car-icon"/><div>Select a vehicle</div>
                                        </Button>
                                    </div>
                                    <h4>{formatDate( selectedScore.date )}</h4>
                                    {me?.level !== 2 && me?.level !== 3 && 
                                        <h4>Dealer: {selectedScore.organization}</h4>}
                                    <h4>Submitted by: {selectedScore.dealerName}</h4>
                                    {selectedScore.cosignerName !== "" && selectedScore.cosignerName !== undefined &&
                                        ( <div className="cosigner-score">
                                            <Divider>
                                                <Chip label="COSIGNER SCORE" />
                                            </Divider>
                                            {me?.level !== 3 &&
                                                <DeclineLetter name={selectedScore.cosignerName} date={selectedScore.date}/>}
                                            <div className="cosigner-header">
                                                <h3>Cosigner: {selectedScore.cosignerName}</h3>
                                                <h3>Score: {selectedScore.totalCosignerScore}</h3>
                                            </div>
                                            {selectedScore.cosignerOptions.map(( c: any ) => (
                                                <p key={c.category}><span className="category-name">{c.category}:</span> {c.value}</p>
                                            ))}
                                        </div> )}
                                    <div className="buyer-score">
                                        <Divider>
                                            <Chip label="BUYER SCORE" />
                                        </Divider>
                                        {me?.level !== 3 &&
                                            <DeclineLetter name={selectedScore.customerName} date={selectedScore.date}/>                                        }
                                        <div className="cosigner-header">
                                            <h3>Buyer: {selectedScore.customerName}</h3>
                                            <h3>Score: {selectedScore.totalBuyerScore}</h3>
                                        </div>
                                        <div className="score-message">
                                            Congratulations you scored a {selectedScore.totalBuyerScore}, {selectedScore.cosignerName !== "" && selectedScore.cosignerName !== undefined ? `and your cosigner scored a ${selectedScore.totalCosignerScore},` : ""} please speak with your salesperson and select a vehicle that is available with your score.
                                        </div>
                                        {selectedScore.buyerOptions.map(( b: any ) => (
                                            <p key={b.category}><span className="category-name">{b.category}:</span> {b.value}</p>
                                        ))}
                                    </div>
                                </div> )}
                        </div>
                        
                    </Grid>

                    {( scores === undefined || scores.length === 0 ) &&
                        <h3>No scores have been calculated.</h3>}
                </> )}
        </Grid>
    )
}

export default ViewScores