import { useAddCategoryMutation, useDeleteCategoryMutation, useEditCategoryMutation, useGetBuyerCategoriesQuery, useGetCosignerCategoriesQuery, useUpdateCategoriesMutation } from '../../../api/categories/api.categories'
import './CategoryManagement.scss'
import { Box } from "grommet"

import { DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay } from '@dnd-kit/core'
import { arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Category } from '../../../datatypes/categories'
import EditIcon from "@mui/icons-material/Edit"
import DragIcon from "@mui/icons-material/Menu"
import AddIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/Delete"
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { weightInvalid } from './../../../util'



import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { forwardRef, useEffect, useState } from 'react'
import { Divider, List, ListItemButton, ListItemText, TextField, Container, IconButton, Dialog, DialogTitle, DialogActions, Button, Tabs, Tab, DialogContent } from '@mui/material'

export const Item = ( props: any ) => {
    const styles = {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        color: "black",
        fontWeight: "bold"
    }
    const { category } = props
    const [ editDialogOpen, setEditDialogOpen ] = useState( false )
    const [ deleteDialogOpen, setDeleteDialogOpen ] = useState( false )
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    
    function openDeleteDialog () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            const thisCategory = ( category.type === "buyer" ? buyerCategories : cosignerCategories )
            if ( thisCategory !== undefined ) {
                const typeHasAtLeastOneCategory = thisCategory.length > 1
                if ( typeHasAtLeastOneCategory ) {
                    setDeleteDialogOpen( true )
                } else {
                    alert( "Category section must have at least one category for both buyers and cosigners." )
                }
            }
        }
    }

    return (
        
        <div style={styles}>
            <EditCategoryDialog open={editDialogOpen} setOpen={setEditDialogOpen} category={category}/>
            <DeleteCategoryDialog open={deleteDialogOpen} setOpen={setDeleteDialogOpen} category={category}/>

            #{category.order}: {category.display_name}
            <div className="item-action-button-container">
                <IconButton onClick={() => setEditDialogOpen( true )}>
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={openDeleteDialog}>
                    <DeleteIcon/>
                </IconButton>
            </div>
        </div>
    )
}

function EditCategoryDialog ( props: any ) {
    const { open, setOpen, category } = props
    const [ name, setName ] = useState( category.display_name )
    const [ weight, setWeight ] = useState( category.weight )
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    const [ editCategory, categoryEditedResult ] = useEditCategoryMutation()

    useEffect(() => {
        if ( open ) {
            setName( category.display_name )
            setWeight( category.weight )
        }
    }, [ open ])

    const handleNameChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setName( event.target.value )
    }
    function editOption () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            if ( !weightInvalid( weight )) {
                if ( !categoryInvalid( name, category.type === "buyer" ? buyerCategories: cosignerCategories, category._id )) {
                    const weightNum = parseFloat( weight )
                    if ( !isNaN( weightNum )) {
                        editCategory({ id: category._id, name: name.trim(), weight: weightNum })
                        handleClose()   
                    }
                } else {
                    if ( name === "" ) {
                        alert( "Category name is empty. Please enter a name for the category." )
                    } else {
                        alert( "Category name is invalid. Please enter a category name that hasn't already been used for a " + category.type )
                    }
                }
            }
            
        } else {
            alert( "Category weight is invalid. Please enter a positive or negative number." )
        }
    }

    function handleClose () {
        setName( "" )
        setWeight( "" )
        setOpen( false )
    }
    return (
        <Dialog className="edit-dialog" open={open} onClose={() => setOpen( false )}>
            <DialogTitle>Edit Category</DialogTitle>
            <div className="edit-content">
                <TextField label="Category Name" variant="outlined" value={name} fullWidth error={category.type === "buyer" ? categoryInvalid( name, buyerCategories ?? [], category._id ) : categoryInvalid( name, cosignerCategories ?? [], category._id )} onChange={handleNameChange}/>
                <TextField label="Value" variant="outlined" error={weightInvalid( weight )} value={weight} onChange={( e ) => setWeight( e.target.value )}/> 
            </div>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={editOption}>Update Category</Button>
            </DialogActions>
        </Dialog>
    )
}



function DeleteCategoryDialog ( props: any ) {
    const { open, setOpen, category, deleted } = props

    const [ deleteCategory, categoryDeletedResult ] = useDeleteCategoryMutation()
    const [ updateCategories, categoriesUpdatedResult ] = useUpdateCategoriesMutation()

    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    
    function deleteAndClose () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            deleteCategory( category._id )
            if ( category.type === "buyer" ) {
                const adjusted = buyerCategories.filter(( cat ) => cat._id !== category._id )
                const newReorderedCategories = adjusted.map(( cat: any, i ) => {
                    return { ...cat, order: i + 1 }
                })
                updateCategories( newReorderedCategories )
            } else {
                const adjusted = cosignerCategories.filter(( cat ) => cat._id !== category._id )
                const newReorderedCategories = adjusted.map(( cat: any, i ) => {
                    return { ...cat, order: i + 1 }
                })
                updateCategories( newReorderedCategories )
            }
            setOpen( false )
        }
    }
    
    return (
        <Dialog className="edit-dialog" open={open} onClose={() => setOpen( false )}>
            <DialogTitle>Delete Category</DialogTitle>
            <DialogContent>
                <h4>Are you sure you want to delete {category.display_name}?</h4>
                <h4>This will permanently delete this category and any options that were added to it!</h4>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={deleteAndClose}>Delete Category</Button>
            </DialogActions>
        </Dialog>
    )
}

function NewCategoryDialog ( props: any ) {
    const { open, setOpen, initialType } = props

    const [ addCategory, categoryAddedResult ] = useAddCategoryMutation()
    const [ name, setName ] = useState( "" )
    const [ weight, setWeight ] = useState( "" )
    const [ type, setType ] = useState( initialType )
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()

    const handleNameChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setName( event.target.value )
    }

    const handleWeightChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        setWeight( event.target.value )
    }

    const handleTypeChange = ( event: SelectChangeEvent ) => {
        setType( event.target.value )
    }

    useEffect(() => {
        if ( open ) {
            setType( initialType )
        }
    }, [ open ])

    function addAndClose () {
        if ( buyerCategories !== undefined && cosignerCategories !== undefined ) {
            if ( !weightInvalid( weight )) {
                if ( !categoryInvalid( name, type === "buyer" ? buyerCategories: cosignerCategories )) {
                    const weightNum = parseFloat( weight )
                    if ( !isNaN( weightNum )) {
                        addCategory({ type: type, name: name.trim(), weight: weightNum, order: type === "buyer" ? buyerCategories.length + 1 : cosignerCategories.length + 1 })
                        handleClose()   
                    }
                } else {
                    if ( name === "" ) {
                        alert( "Category name is empty. Please enter a name for the category." )
                    } else {
                        alert( "Category name is invalid. Please enter a category name that hasn't already been used for a " + type )
                    }
                }
            } else {
                alert( "Category weight is invalid. Please enter a positive or negative number." )
            }
        }
    }

    function handleClose () {
        setName( "" )
        setType( "buyer" )
        setWeight( "" )
        setOpen( false )
    }
  
    return (
        <Dialog className="edit-dialog add" open={open} onClose={handleClose}>
            <DialogTitle>Add New Category</DialogTitle>
            <DialogContent>
                <div className="edit-content">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={type}
                          label="Category Type"
                          onChange={handleTypeChange}>
                            <MenuItem value={"buyer"}>Buyer</MenuItem>
                            <MenuItem value={"cosigner"}>Cosigner</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField fullWidth error={type === "buyer" ? categoryInvalid( name, buyerCategories ?? []) : categoryInvalid( name, cosignerCategories ?? [])} label="Category Name" variant="outlined" value={name} onChange={handleNameChange}/>
                    <TextField label="Value" variant="outlined" error={weightInvalid( weight )} value={weight} onChange={handleWeightChange}/> 
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen( false )}>Cancel</Button>
                <Button onClick={addAndClose}>Add Category</Button>
            </DialogActions>
        </Dialog>
    )
}

function categoryInvalid ( category: string, categories: Category[], currentId?: string ) {
    
    if ( category !== "" ) {
        if ( currentId !== undefined && categories.find(( cat ) => cat._id === currentId )?.display_name === category.trim()) {
            return false
        } else {
            const valueArr = categories.map( function ( cat ){ 
                return cat.display_name 
            })
            const isDuplicate = valueArr.indexOf( category.trim()) !== - 1
            return isDuplicate
        }
    } else {
        return true
    }
}


function CardsWrapper ( props: any ) {
    return (
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            padding: "2rem",
            height: "100%",
        }}>
            {props.children}
        </div>
    )
}


export function SortableItem ( props: any ) {
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id })
    
    const style = {
        transform: CSS.Transform.toString( transform ),
        transition,
        border: "2px solid lightgrey",
        borderRadius: "1vmin",
        backgroundColor: "white",
        display: "flex",
        padding: "0.5rem",
        margin: "0 0.5rem 0.5rem 0",
        maxWidth: "30vw",
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1
    }
    
    return (
        <div
        ref={setNodeRef}
        style={style}
        faded={isDragging.toString()}
        {...props}>
            <IconButton {...attributes} {...listeners} >
                <DragIcon/>
            </IconButton>
            <Item category={props.category}/>
        </div>
    )
}

function CategoryManagement () {
    const { data: buyerCategories } = useGetBuyerCategoriesQuery()
    const { data: cosignerCategories } = useGetCosignerCategoriesQuery()
    const [ updateCategories, categoriesUpdatedResult ] = useUpdateCategoriesMutation()

    const [ newCategoryOpen, setNewCategoryOpen ] = useState( false )
    const [ newBuyerCategories, setNewBuyerCategories ] = useState<any>([])
    const [ newCosignerCategories, setNewCosignerCategories ] = useState<any>([])
    // buyer = 0, cosigner = 1
    const [ selectedType, setSelectedType ] = useState( 0 )
    const [ selectedCategory, setSelectedCategory ] = useState( 0 )

    const sensors = useSensors(
        useSensor( PointerSensor ),
        useSensor( KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )


    useEffect(() => {
        if ( buyerCategories !== undefined ) {
            setNewBuyerCategories( buyerCategories )
        }
    }, [ buyerCategories ])

    useEffect(() => {
        if ( cosignerCategories !== undefined ) {
            setNewCosignerCategories( cosignerCategories )
        }
    }, [ cosignerCategories ])



    function handleDragEnd ( event: any, id: string ) {
        const { active, over } = event
        
        if ( active.id !== over.id ) {
            if ( selectedType === 0 ) {

                try {
                    const newCategories = rearrangeCategory( active, over, newBuyerCategories )
                    updateCategories( newCategories )

                    setNewBuyerCategories( newCategories )
                } catch ( err ) {
                    console.error( "could not update buyer category" )
                }
            } else {
                try {
                    const newCategories = rearrangeCategory( active, over, newCosignerCategories )
                    updateCategories( newCategories )
                    setNewCosignerCategories( newCategories )
                } catch ( err ) {
                    console.error( "could not update cosigner category" )
                }
            }
        }
    }

    function rearrangeCategory ( active: any, over: any, editingCategory: any ) {
        const oldIndex = editingCategory.indexOf( editingCategory.find(( cat: Category ) => cat._id === active.id ))
        const newIndex = editingCategory.indexOf( editingCategory.find(( cat: Category ) => cat._id === over.id ))
        const newCategories = arrayMove( editingCategory, oldIndex, newIndex )
        const newReorderedCategories = newCategories.map(( cat: any, i ) => {
            return { ...cat, order: i + 1 }
        })

        return newReorderedCategories
    }

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedCategory( index )
    }

    const [ activeId, setActiveId ] = useState( null )

    const handleDragStart = ( event: any ) => {
        setActiveId( event.active.id )
    }
     
    function changeCategorySet ( index: number ) {
        setSelectedType( index )
        // setCategory( index === 0 ? newBuyerCategories : newCosignerCategories )
        setSelectedCategory( 0 )
    }
      
    return (
        <div className="category-management">
            <NewCategoryDialog open={newCategoryOpen} setOpen={setNewCategoryOpen} initialType={selectedType === 0 ? "buyer" : "cosigner"}/>
            <Container>
                <div className="tab-list-container">
                    <div className="tab-buttons">
                        <Button className={selectedType === 0 ? "selected" : ""} onClick={() => changeCategorySet( 0 )}>
                            Buyer
                        </Button>
                        <Button className={selectedType === 1 ? "selected" : ""} onClick={() => changeCategorySet( 1 )}>
                            Cosigner
                        </Button>
                        <IconButton onClick={() => setNewCategoryOpen( true )}>
                            <AddIcon/>
                        </IconButton>
                    </div>
                    <Divider/>
                    {selectedType === 0 && newBuyerCategories !== undefined && newBuyerCategories.length > 0 &&
                      ( <div className="buyer-reorder">     
                          <DndContext sensors={sensors} 
                                      collisionDetection={closestCenter} 
                                      onDragStart={handleDragStart}
                                      onDragEnd={( e ) => handleDragEnd( e, newBuyerCategories[selectedCategory]._id )}>
                                  
                              <SortableContext  items={newBuyerCategories.map(( a: any ) => a._id )} strategy={verticalListSortingStrategy}>
                                  <CardsWrapper >
                                      {newBuyerCategories.map(( cat: any, i: number ) => (
                              
                                          <SortableItem key={cat.display_name} id={cat._id} category={cat} index={i}/>
                                      ))}
                                  </CardsWrapper>
                              </SortableContext>
                          </DndContext> 
                      </div> )}

                    {selectedType === 1 && newCosignerCategories !== undefined && newCosignerCategories.length > 0 &&
                      ( <div className="buyer-reorder">     
                          <DndContext sensors={sensors} 
                                      collisionDetection={closestCenter} 
                                      onDragStart={handleDragStart}
                                      onDragEnd={( e ) => handleDragEnd( e, newCosignerCategories[selectedCategory]._id )}>
                                  
                              <SortableContext  items={newCosignerCategories.map(( a: any ) => a._id )} strategy={verticalListSortingStrategy}>
                                  <CardsWrapper >
                                      {newCosignerCategories.map(( cat: any, i: number ) => (
                              
                                          <SortableItem key={cat.display_name} id={cat._id} category={cat} index={i}/>
                                      ))}
                                  </CardsWrapper>
                              </SortableContext>
                          </DndContext> 
                      </div> )}
              
                </div>
            </Container>
        </div>
    )
}
export default CategoryManagement