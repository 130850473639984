import { BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { HostInfo } from './host'
import { logout } from './logout'
  
function baseUrl (): string {
    const host = new HostInfo()
    const hostUrl = host.GetAPIHost()
    const url = hostUrl + "/"
    return url
}
  
// Create a new mutex
const mutex = new Mutex()
  
const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
})
  
const customFetchBase: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async ( args, api, extraOptions ) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock()
    // const unprotectedRoutes = [ "/reset", "reset-password", "forgot-password" ]
    //console.log(( args as FetchArgs ))
    let result = await baseQuery( args, api, extraOptions )
    // console.log( result )
    // console.log( result.meta?.response?.status )
    if (( args as FetchArgs ).credentials === "include" ) {
        if (( result.meta?.response?.status ) === 403 ) {
            if ( localStorage.getItem( "logged in" ) === "true" ) {
            //console.log( "error" )
                if ( !mutex.isLocked()) {
                    const release = await mutex.acquire()
  
                    try {
                        const refreshResult = await baseQuery(
                            { credentials: 'include', url: '/sessions' },
                            api,
                            extraOptions
                        )
                        //console.log( refreshResult )
                        if ( refreshResult.data ) {
                            localStorage.setItem( "logged in", "true" )
                            const meResult = await baseQuery(
                                { credentials: 'include', url: '/me' },
                                api,
                                extraOptions
                            )
                            //console.log( "DIDNT ERROR", refreshResult )
                            // Retry the initial query
                            result = await baseQuery( args, api, extraOptions )
                        } else {
                            localStorage.setItem( "logged in", "false" )
                            api.dispatch( logout())
                            // console.log( "go to login 2" )
                            window.location.href = '/'
                        }
                    } finally {
                        // release must be called once the mutex should be released again.
                        release()
                    }
                }
            } else {
                api.dispatch( logout())
                // console.log( "go to login" )
                if ( window.location.href !== "/" ) {
                    window.location.href = '/'
                }
            }
        }
        // else {
        // // wait until the mutex is available without locking it
        //     await mutex.waitForUnlock()
        //     result = await baseQuery( args, api, extraOptions )
        // }
    }
  
    return result
}
  
export default customFetchBase