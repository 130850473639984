import { Container, TextField, Button } from '@mui/material'
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/dist/query'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetMeQuery, useLoginMutation, usePasswordResetMutation } from '../../api/auth/api.auth'
import './ForgotPassword.scss'

function ForgotPassword () {
    const navigate = useNavigate()

    const [ email, setEmail ] = useState( "" )
    const [ passwordResetRequest, passwordResetRequestResult ] = usePasswordResetMutation()
    const [ requestMade, setRequestMade ] = useState( false )
    
    function login () {
        if ( email === "" ) {
            alert( "Email address is empty. Please enter the email address associated with your Car Scores account." )
        } else {
            passwordResetRequest( email )
        }
    }

    function hitEnter ( e: any ) {
        if ( e.key === 'Enter' || e.code === "Enter" ) {
            login()
        }
    }

    useEffect(() => {
        if ( passwordResetRequestResult.status === QueryStatus.fulfilled || passwordResetRequestResult.status === QueryStatus.rejected ) {
            if ( passwordResetRequestResult.isSuccess ) {
                setRequestMade( true )
            } else {
                alert( "An error has occurred." )
            }
        }
    }, [ passwordResetRequestResult ])


    return (
        <div className="forgot-password">
            {!requestMade &&
                ( <div>
                    <h4>Reset Password</h4>
                    <p>Enter the email address associated with your account.</p>
                    <div className="login-inputs">
                        <TextField fullWidth 
                                   label="Email address" 
                                   variant="outlined" 
                                   value={email} 
                                   onChange={( e: any ) => setEmail( e.target.value )}/>
                
                    </div>
                    <Button className="login-btn" variant="contained" onClick={() => login()}>Reset Password</Button>
                </div> )}
            {requestMade &&
                    ( <div className="request-made-msg">
                        <h3>If an account exists at the email you entered, a password reset link will be sent to it shortly.</h3>
                        <h3>Please be sure to check your spam folder if the email does not appear in your inbox.</h3>
                    </div> )}
        </div>
    )
}

export default ForgotPassword